.cardBump {
  color: #474747;
  background-color: #fff;
  padding: 10px;
  border-radius: 6px;
  border: red dashed 4px;
  line-height: 1.8rem;

  h1 {
    font-size: 1.5rem;
  }

  .content {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-around;
    align-items: center;
    gap: 15px;

    .imageContainer {
      min-width: 35%;
      max-width: 250px;

      img {
        width: 100%;
        height: auto;
        border-radius: 6px;
      }
    }

    .textContainer {
      min-width: 35%;

      .bumpPrice {
        color: #ec6331;
        font-size: 2.1rem;
        font-weight: 500;
        margin: 15px auto;
      }

      .crossedOutPrice {
        color: #7e7a7a;
        font-size: 1.5rem;
        text-decoration: line-through;
        margin: 15px auto;
      }

      .textPromo {
        color: #ec6331;
        font-size: 1.5rem;
        font-weight: 500;
        border-radius: 5px;
        padding: 5px;
        width: fit-content;
        margin: 10px auto;
      }
    }
  }
}

.crossedOutPrice {
  text-decoration: line-through;
}

.percentPrice {
  color: #ec6331;
  font-weight: 600;
}
