body {
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  margin: 0;
}

html {
  width: 100%;
  height: 100%;
}

img {
  max-width: 100%;
}

@-webkit-keyframes gradient {
  0% {
    background-position: 0% 50%;
  }

  50% {
    background-position: 80% 50%;
  }

  100% {
    background-position: 0% 50%;
  }
}

@keyframes gradient {
  0% {
    background-position: 0% 50%;
  }

  50% {
    background-position: 80% 50%;
  }

  100% {
    background-position: 0% 50%;
  }
}

#root {
  display: block !important;
}

.page-neutre {
  background: linear-gradient(-45deg, #ed5199, #ed5199, #ea570d, #ea570d);
  background-size: 450% 450%;
  -webkit-animation: gradient 5s ease infinite;
          animation: gradient 5s ease infinite;
  margin: 0;
  max-width: 100%;
}

.page-femme {
  background: linear-gradient(-45deg, #ed5199, #ddd, #b46e9f, #dd7ba1);
  background-size: 400% 400%;
  -webkit-animation: gradient 15s ease infinite;
          animation: gradient 15s ease infinite;
  margin: 0;
  max-width: 100%;
}

.page-homme {
  background: linear-gradient(-45deg, #ee7752, #ddd, #ea570d, #e73c7e);
  background-size: 400% 400%;
  -webkit-animation: gradient 15s ease infinite;
          animation: gradient 15s ease infinite;
  margin: 0;
  max-width: 100%;
}

/*alert stripe test*/
.alert {
  font-size: 1.5em;
  width: 100%;
  background-color: #ff0000;
  text-align: center;
  font-style: unset;
  color: rgb(255, 255, 255);
  position: fixed;
  bottom: 0px;
  padding: 10px;
}

/* Margin pour inputs */
.MuiFormControl-root {
  margin-bottom: 10px;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

@media (max-width: 800px) {
  body {
    width: 100vw !important;
  }
}


/*---------------------- SELECT PAIEMENT --------------------------*/
.paymentFormCtn {
  width: 100%;
  margin-bottom: 10px;
}

.paymentForm {
  display: flex;
  width: 100% !important;
  align-self: center;
}

.paymentFormLabel {
  /* font-weight: bold !important;
  font-size: 1.2rem !important; */
}

.paymentForm__btn {
  display: block;
  width: 30%;
  margin: 1rem auto;
  border: none;
  padding: .5rem;
}

/*---------------------- RECAP COMMAND --------------------------*/
.recap-command {
  display: block;
  margin: 15px 10px 30px 10px;
}

.title-command,
.product-command,
.total-command {
  display: flex;
  text-align: start;
  justify-content: space-between;
  grid-gap: 10px;
  gap: 10px;
  padding: 0px;
}

.title-command {
  border-bottom: 1px solid black;
  padding-bottom: 5px;
  font-size: 1.2em;
  font-weight: 700;
}

.total-command {
  border-top: 1px solid black;
  margin-top: 1rem;
  padding-bottom: 5px;
  font-size: 1.2em;
  font-weight: 700;
}

.product-command {
  margin-top: 10px;
}

.total-before-reduc {
  display: flex;
  text-align: start;
  justify-content: end;
  border-top: 1px solid black;
  margin-top: 1rem;
  padding-bottom: 5px;
  font-size: 1.2em;
  font-weight: 700;
  text-decoration: line-through;
  -webkit-text-decoration-color: #ea570d;
          text-decoration-color: #ea570d;
  -webkit-text-decoration-style: wavy;
          text-decoration-style: wavy;
}

.total-reduc {
  display: flex;
  text-align: start;
  justify-content: end;
}

.reduc-percent {
  display: flex;
  color: white;
  font-weight: 500;
  background-color: #ea570d;
  border-radius: 10px;
  width: -webkit-fit-content;
  width: -moz-fit-content;
  width: fit-content;
  margin: 5px;
}

.product-price,
.bonus-price,
.reduc-percent {
  align-self: end;
  padding: 10px;
}

/*---------------------- secure icone checkout auto --------------------------*/
.container-secure-icons {
  margin: 15px;
}

/*---------------------- alma checkout auto --------------------------*/

.divEligibility {
  text-align: start;
}
.routing {
    height: 100vh;
    height: calc(1vh * 100);
    height: calc(var(--vh, 1vh) * 100);
    max-width: 100%;
    margin: 0;
    display: flex;
    font-family: arial;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    text-align: center;
    background: rgb(255, 255, 255);
    background: linear-gradient(190deg,
            rgb(255, 255, 255) 0%,
            rgb(255, 255, 255) 100%);
}

.route {
    margin: 1em;
}

.routing-table {
    border: solid #afafaf 2px;
    border-radius: 10px;
    margin: 10px;
    align-items: center;
    margin-top: 20px;
}

.btn {
    text-align: center;
    width: 200px;
    text-decoration: none;
    padding: 10px;
    font-family: arial;
    font-size: 1.2em;
    font-style: bold;
    color: #ffffff !important;
    background-color: #ff762cd5;
    border-radius: 10px;
    -webkit-border-radius: 10px;
    -moz-border-radius: 10px;
    display: block;
}

.btn-large {
    text-align: center;
    text-decoration: none;
    padding: 10px;
    font-family: arial;
    font-size: 1.2em;
    font-style: bold;
    color: #ffffff !important;
    background-color: #ff762cd5;
    border-radius: 10px;
    -webkit-border-radius: 10px;
    -moz-border-radius: 10px;
    display: block;
    cursor: pointer;
}

.fbtn {
    background-color: #ed519ad7;
}

.obtn {
    background-color: #d43a3ad5;
}

.btn:hover {
    cursor: pointer;
    -webkit-transform: scale(1.1);
            transform: scale(1.1);
    transition: all 0.2s ease-in-out;
}

.text-white {
    color: #fff;
    text-decoration: none;
    font-weight: 500;
}

.title-routing {
    color: #363434;
}

h2 {
    margin-top: -18px;
    margin-left: auto;
    margin-right: auto;
    background-color: #ffffff;
    max-width: 95%;
    color: #363434;
    border: solid #afafafc9 2px;
    border-radius: 10px;
    padding: 0px;
    font-family: arial;
}
.routing {
    height: 100vh;
    height: calc(1vh * 100);
    height: calc(var(--vh, 1vh) * 100);
    max-width: 100%;
    margin: 0;
    display: flex;
    font-family: arial;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    text-align: center;
    background: rgb(255, 255, 255);
    background: linear-gradient(190deg,
            rgb(255, 255, 255) 0%,
            rgb(255, 255, 255) 100%);
}

.route {
    margin: 1em;
}

input {
    width: 320px;
    text-align: center;
}

.mail {
    padding: 5px;
}

.routing-table {
    border: solid #afafaf 2px;
    border-radius: 10px;
    margin: 10px;
    align-items: center;
    margin-top: 20px;
}

.btn {
    text-align: center;
    width: 200px;
    text-decoration: none;
    padding: 10px;
    font-family: arial;
    font-size: 1.2em;
    font-style: bold;
    color: #ffffff !important;
    background-color: #ff762cd5;
    border-radius: 10px;
    -webkit-border-radius: 10px;
    -moz-border-radius: 10px;
    display: block;
}

.fbtn {
    background-color: #ed519ad7;
}

.obtn {
    background-color: #d43a3ad5;
}

.btn:hover {
    -webkit-transform: scale(1.1);
            transform: scale(1.1);
    transition: all 0.2s ease-in-out;
}

.text-white {
    color: #fff;
    text-decoration: none;
    font-weight: 500;
}

.title-routing {
    color: #363434;
}

h2 {
    margin-top: -18px;
    margin-left: auto;
    margin-right: auto;
    background-color: #ffffff;
    max-width: 95%;
    color: #363434;
    border: solid #afafafc9 2px;
    border-radius: 10px;
    padding: 0px;
    font-family: arial;
}
form {
  width: 30vw;
  align-self: center;
  box-shadow: 0px 0px 0px 0.5px rgba(50, 50, 93, 0.1),
    0px 2px 5px 0px rgba(50, 50, 93, 0.1), 0px 1px 1.5px 0px rgba(0, 0, 0, 0.07);
  border-radius: 7px;
  padding: 40px;
  margin: auto;
}

input {
  border-radius: 6px;
  margin-bottom: 6px;
  padding: 12px;
  border: 1px solid rgba(50, 50, 93, 0.1);
  max-height: 44px;
  font-size: 16px;
  width: 100%;
  background: white;
  box-sizing: border-box;
}

h3 {
  color: dimgray;
}

.result-message {
  line-height: 22px;
  font-size: 16px;
}

.result-message a {
  color: rgb(89, 111, 214);
  font-weight: 600;
  text-decoration: none;
}

.hidden {
  display: none;
}


#card-error {
  color: rgb(105, 115, 134);
  font-size: 16px;
  line-height: 20px;
  margin-top: 12px;
  text-align: center;
}

#card-element {
  border-radius: 4px 4px 0 0;
  padding: 12px;
  border: 1px solid rgba(50, 50, 93, 0.1);
  max-height: 44px;
  width: 100%;
  background: white;
  box-sizing: border-box;
}

#payment-request-button {
  margin-bottom: 32px;
}

/* Buttons and links */
button {
  background: #5469d4;
  font-family: Arial, sans-serif;
  color: #ffffff;
  border-radius: 0 0 4px 4px;
  border: 0;
  padding: 12px 16px;
  font-size: 16px;
  font-weight: 600;
  cursor: pointer;
  display: block;
  transition: all 0.2s ease;
  box-shadow: 0px 4px 5.5px 0px rgba(0, 0, 0, 0.07);
  width: 100%;
}

button:hover {
  -webkit-filter: contrast(115%);
          filter: contrast(115%);
}

button:disabled {
  opacity: 0.5;
  cursor: default;
}

.process-payment {
  background: #5469d4;
  font-family: Arial, sans-serif;
  color: #ffffff;
  border-radius: 4px 4px 4px 4px;
  border: 0;
  padding: 12px 16px;
  font-size: 16px;
  font-weight: 600;
  cursor: pointer;
  display: block;
  transition: all 0.2s ease;
  box-shadow: 0px 4px 5.5px 0px rgba(0, 0, 0, 0.07);
  width: 100%;
}

.process-payment:hover {
  -webkit-filter: contrast(115%);
          filter: contrast(115%);
}

.process-payment:disabled {
  opacity: 0.5;
  cursor: default;
}

/* spinner/processing state, errors */
.spinner,
.spinner:before,
.spinner:after {
  border-radius: 50%;
}

.spinner {
  color: #ffffff;
  font-size: 22px;
  text-indent: -99999px;
  margin: 0px auto;
  position: relative;
  width: 20px;
  height: 20px;
  box-shadow: inset 0 0 0 2px;
  -webkit-transform: translateZ(0);
  transform: translateZ(0);
}

.spinner:before,
.spinner:after {
  position: absolute;
  content: "";
}

.spinner:before {
  width: 10.4px;
  height: 20.4px;
  background: #5469d4;
  border-radius: 20.4px 0 0 20.4px;
  top: -0.2px;
  left: -0.2px;
  -webkit-transform-origin: 10.4px 10.2px;
  transform-origin: 10.4px 10.2px;
  -webkit-animation: loading 2s infinite ease 1.5s;
  animation: loading 2s infinite ease 1.5s;
}

.spinner:after {
  width: 10.4px;
  height: 10.2px;
  background: #5469d4;
  border-radius: 0 10.2px 10.2px 0;
  top: -0.1px;
  left: 10.2px;
  -webkit-transform-origin: 0px 10.2px;
  transform-origin: 0px 10.2px;
  -webkit-animation: loading 2s infinite ease;
  animation: loading 2s infinite ease;
}

@-webkit-keyframes loading {
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }

  100% {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}

@keyframes loading {
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }

  100% {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}

@media only screen and (max-width: 600px) {
  form {
    width: 80vw;
  }
}

@media (max-width: 800px) {
  form {
    width: 80vw;
    padding: 10px;
  }
}
.react-select{padding:.5rem}.react-select .react-select__control{background-color:rgba(17,255,238,0);height:50px}
/**
* Shows how you can use CSS to style your Element's container.
* These classes are added to your Stripe Element by default.
* You can override these classNames by using the options passed
* to the IbanElement component.
* https://stripe.com/docs/js/elements_object/create_element?type=iban#elements_create-options-classes
*/
input,
.StripeElement {
    margin-top: 20px;
    padding: 10px 12px;
    color: #32325d;
    background-color: white;
    border: 1px solid transparent;
    border-radius: 4px;
    box-shadow: 0 1px 3px 0 #e6ebf1;
    transition: box-shadow 150ms ease;
}

input:focus,
.StripeElement--focus {
    box-shadow: 0 1px 3px 0 #cfd7df;
}

.StripeElement--invalid {
    border-color: #fa755a;
}

.StripeElement--webkit-autofill {
    background-color: #fefde5 !important;
}
#mandate-acceptance {
    font-style: italic;
    margin-top: 20px;
    font-size: 14px;
    text-align: justify;
}

.card-error {
    margin-top: 15px;
}
.card-error-renew {
    margin-top: 10px;
    margin-bottom: 10px;
}
.cardBump{color:#474747;background-color:#fff;padding:10px;border-radius:6px;border:red dashed 4px;margin-top:20px;line-height:1.8rem}.cardBump h1{font-size:1.5rem}.cardBump .content{display:flex;flex-wrap:wrap;justify-content:space-around;align-items:center;grid-gap:15px;gap:15px}.cardBump .content .imageContainer{min-width:35%;max-width:250px}.cardBump .content .imageContainer img{width:100%;height:auto;border-radius:6px}.cardBump .content .textContainer{min-width:35%}.cardBump .content .textContainer .bumpPrice{color:#ec6331;font-size:2.1rem;font-weight:500;margin:15px auto}.cardBump .content .textContainer .crossedOutPrice{color:#7e7a7a;font-size:1.5rem;text-decoration:line-through;margin:15px auto}.cardBump .content .textContainer .textPromo{color:#ec6331;font-size:1.5rem;font-weight:500;border-radius:5px;padding:5px;width:-webkit-fit-content;width:-moz-fit-content;width:fit-content;margin:10px auto}.crossedOutPrice{text-decoration:line-through}.percentPrice{color:#ec6331;font-weight:600}
.icons {
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
}

.icon-container {
    width: -webkit-fit-content;
    width: -moz-fit-content;
    width: fit-content;
    cursor: pointer;
    font-size: 1.5em;
    margin: auto 3rem;
    transition: all 0.3s ease-in-out;
}

.icon-container>p {
    margin-top: 16px;
}

svg {
    max-width: 100%;
    height: -webkit-fit-content;
    height: -moz-fit-content;
    height: fit-content;
}

.icon {
    width: 160px;
    min-width: 150px;
    max-width: 160px;
    height: 160px;
    min-height: 150px;
    max-height: 160px;
}

.icon-container:hover {
    color: #ec6331;
    font-weight: 600;
    /* transition: all 5s ease-in-out; */
}

.icon-container .icon {
    -webkit-transform: scale(1);
            transform: scale(1);
    transition: -webkit-transform 0.3s ease-in-out;
    transition: transform 0.3s ease-in-out;
    transition: transform 0.3s ease-in-out, -webkit-transform 0.3s ease-in-out;
}

.icon-container:hover .icon {
    -webkit-transform: scale(1.1);
            transform: scale(1.1);
    position: relative;
}

.icon-container:hover .icon::before {
    content: ' ';
    display: block;

    width: 100%;
    height: 100%;

    padding: 5px;

    border: 1.5px solid #fbd7c9;
    border-right: none;
    border-top: none;
    border-radius: 50%;

    position: absolute;
    top: -5.8px;
    left: -6px;

    -webkit-animation: border_rounded_load 1.5s linear infinite;

            animation: border_rounded_load 1.5s linear infinite;
}


.title-gender {
    margin: -20px auto 20px auto;
    background-color: #ffffff;
    max-width: 90%;
    color: #363434;
    font-size: 1.5rem;
    font-weight: 550;
    border-radius: 10px;
    padding: 5px;
    font-family: arial;
}
.cardBump{color:#474747;background-color:#fff;padding:10px;border-radius:6px;border:red dashed 4px;line-height:1.8rem}.cardBump h1{font-size:1.5rem}.cardBump .content{display:flex;flex-wrap:wrap;justify-content:space-around;align-items:center;grid-gap:15px;gap:15px}.cardBump .content .imageContainer{min-width:35%;max-width:250px}.cardBump .content .imageContainer img{width:100%;height:auto;border-radius:6px}.cardBump .content .textContainer{min-width:35%}.cardBump .content .textContainer .bumpPrice{color:#ec6331;font-size:2.1rem;font-weight:500;margin:15px auto}.cardBump .content .textContainer .crossedOutPrice{color:#7e7a7a;font-size:1.5rem;text-decoration:line-through;margin:15px auto}.cardBump .content .textContainer .textPromo{color:#ec6331;font-size:1.5rem;font-weight:500;border-radius:5px;padding:5px;width:-webkit-fit-content;width:-moz-fit-content;width:fit-content;margin:10px auto}.crossedOutPrice{text-decoration:line-through}.percentPrice{color:#ec6331;font-weight:600}
.success {
  height: 100vh;
  width: 100vw;
  text-align: center;
  padding-top: 2em;
}

.container {
  /* border: solid 2px #000; */
  border-radius: 0.5em;
  padding: 1em;
  width: 60vh;
  margin: auto;

  background-color: #fff;
  font-weight: 500;
  color: #000;
  box-shadow: 0px 0px 15px 5px rgba(119, 119, 119, 0.72);
}

.triangle{
  width: 50px;
  margin-right: 5px;
  margin-left: 5px;
}

.text {
  margin: 1em;
}

.bandeau {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 1px;
  margin: 10px 2px 10px 2px;
  color: #333;
  background-color: #ccc;
  border-radius: 0.2em;
  font-size: 1.5em;
}

.ctn-footer {
  background: #232526; /* fallback for old browsers */ /* Chrome 10-25, Safari 5.1-6 */
  background: linear-gradient(
    to right,
    #414345,
    #232526
  ); /* W3C, IE 10+/ Edge, Firefox 16+, Chrome 26+, Opera 12+, Safari 7+ */
  border-radius: 0.2em;
}

a {
  color: #ea570d;
}

@media (max-width: 800px) {
  .container {
    max-width: 80%;
  }

  .group {
    width: 300px;
  }
}

.success {
  height: 100vh;
  width: 100vw;
  text-align: center;
  padding-top: 2em;
}

.container {
  /* border: solid 2px #000; */
  border-radius: 0.5em;
  padding: 1em;
  width: 60vh;
  margin: auto;

  background-color: #fff;
  font-weight: 500;
  color: #000;
  box-shadow: 0px 0px 15px 5px rgba(119, 119, 119, 0.72);
}

.text {
  margin: 1em;
  justify-content: center;
  display: flex;

}

.no-decoration {
  text-decoration: none;
}

.bandeau {
  padding: 0.3em;
  margin: auto;
  color: #333;
  background-color: #ccc;
  border-radius: 0.2em;
  font-size: 1.5em;
}

.ctn-footer {
  background: #232526;
  /* fallback for old browsers */
  /* Chrome 10-25, Safari 5.1-6 */
  background: linear-gradient(to right,
      #414345,
      #232526);
  /* W3C, IE 10+/ Edge, Firefox 16+, Chrome 26+, Opera 12+, Safari 7+ */
  border-radius: 0.2em;
}

a {
  color: #ea570d;
}

@media (max-width: 800px) {
  .container {
    max-width: 80%;
  }

  .group {
    width: 300px;
  }
}
.App {
    text-align: center;
    background-color: #e8f0fe90;
    border-radius: 12px;
    padding: 24px;
    margin: 5% 20%;
    display: flex;
}

.center {
    justify-content: center;
    padding: 15px;
    width: -webkit-fit-content;
    width: -moz-fit-content;
    width: fit-content;
    margin: 20px auto;
}

.inputs {
    display: flex;
    flex-direction: column;
}

.error {
    background-color: #f7151590;
}

.mui-input {
    margin: 0.5em;
}

.secure-icons {
    width: 50px;
    height: 50px;
    margin: .5em;
}

.recap {
    font-weight: bold;
    font-size: 24px;
    margin-bottom: 1.3em;
    margin-top: 1.7em;
}

.checkList {
    margin: 2em 1.3em;
    font-size: 20px;
    text-align: start;
}

.logo-triangle {
    max-width: 200px;
    width: 100%;
    margin-top: 50px;
}

.cgv {
    font-size: 0.8em;
}

#payment-form {
    background-color: #e8f0fe;
}

.payment-form-large {
    width: 80%;
}

.border-homme {
    border: 7px solid #ee775299;
}

.border-femme {
    border: 7px solid #ed5199;
}

.priceLine {
    display: flex;
    flex-wrap: wrap;
    margin: 5px 0px;
    grid-gap: 10px;
    gap: 10px;

}

.priceLine>p {
    margin: 0px;
}

.discountPrice {
    color: #fff;
    font-weight: 600;
    background-color: red;
    padding: 5px;
    border-radius: 5px;
}

@media (max-width: 800px) {
    .App {
        border-radius: 12px;
        padding: 6px;
        margin: 5% auto;
        flex-direction: column;
    }

    .secure-icons {
        width: 40px;
        height: 40px;
        margin: .2em;
    }

    #payment-form {
        border: none;
    }
}

@media (max-width: 550px) {
    .App {
        margin: 40px 5px;
    }
}
