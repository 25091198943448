.success {
  height: 100vh;
  width: 100vw;
  text-align: center;
  padding-top: 2em;
}

.container {
  /* border: solid 2px #000; */
  border-radius: 0.5em;
  padding: 1em;
  width: 60vh;
  margin: auto;

  background-color: #fff;
  font-weight: 500;
  color: #000;

  -webkit-box-shadow: 0px 0px 15px 5px rgba(119, 119, 119, 0.72);
  box-shadow: 0px 0px 15px 5px rgba(119, 119, 119, 0.72);
}

.text {
  margin: 1em;
  justify-content: center;
  display: flex;

}

.no-decoration {
  text-decoration: none;
}

.bandeau {
  padding: 0.3em;
  margin: auto;
  color: #333;
  background-color: #ccc;
  border-radius: 0.2em;
  font-size: 1.5em;
}

.ctn-footer {
  background: #232526;
  /* fallback for old browsers */
  background: -webkit-linear-gradient(to right,
      #414345,
      #232526);
  /* Chrome 10-25, Safari 5.1-6 */
  background: linear-gradient(to right,
      #414345,
      #232526);
  /* W3C, IE 10+/ Edge, Firefox 16+, Chrome 26+, Opera 12+, Safari 7+ */
  border-radius: 0.2em;
}

a {
  color: #ea570d;
}

@media (max-width: 800px) {
  .container {
    max-width: 80%;
  }

  .group {
    width: 300px;
  }
}