.App {
    text-align: center;
    background-color: #e8f0fe90;
    border-radius: 12px;
    padding: 24px;
    margin: 5% 20%;
    display: flex;
}

.center {
    justify-content: center;
    padding: 15px;
    width: fit-content;
    margin: 20px auto;
}

.inputs {
    display: flex;
    flex-direction: column;
}

.error {
    background-color: #f7151590;
}

.mui-input {
    margin: 0.5em;
}

.secure-icons {
    width: 50px;
    height: 50px;
    margin: .5em;
}

.recap {
    font-weight: bold;
    font-size: 24px;
    margin-bottom: 1.3em;
    margin-top: 1.7em;
}

.checkList {
    margin: 2em 1.3em;
    font-size: 20px;
    text-align: start;
}

.logo-triangle {
    max-width: 200px;
    width: 100%;
    margin-top: 50px;
}

.cgv {
    font-size: 0.8em;
}

#payment-form {
    background-color: #e8f0fe;
}

.payment-form-large {
    width: 80%;
}

.border-homme {
    border: 7px solid #ee775299;
}

.border-femme {
    border: 7px solid #ed5199;
}

.priceLine {
    display: flex;
    flex-wrap: wrap;
    margin: 5px 0px;
    gap: 10px;

}

.priceLine>p {
    margin: 0px;
}

.discountPrice {
    color: #fff;
    font-weight: 600;
    background-color: red;
    padding: 5px;
    border-radius: 5px;
}

@media (max-width: 800px) {
    .App {
        border-radius: 12px;
        padding: 6px;
        margin: 5% auto;
        flex-direction: column;
    }

    .secure-icons {
        width: 40px;
        height: 40px;
        margin: .2em;
    }

    #payment-form {
        border: none;
    }
}

@media (max-width: 550px) {
    .App {
        margin: 40px 5px;
    }
}