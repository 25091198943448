.routing {
    height: 100vh;
    height: calc(var(--vh, 1vh) * 100);
    max-width: 100%;
    margin: 0;
    display: flex;
    font-family: arial;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    text-align: center;
    background: rgb(255, 255, 255);
    background: linear-gradient(190deg,
            rgb(255, 255, 255) 0%,
            rgb(255, 255, 255) 100%);
}

.route {
    margin: 1em;
}

input {
    width: 320px;
    text-align: center;
}

.mail {
    padding: 5px;
}

.routing-table {
    border: solid #afafaf 2px;
    border-radius: 10px;
    margin: 10px;
    align-items: center;
    margin-top: 20px;
}

.btn {
    text-align: center;
    width: 200px;
    text-decoration: none;
    padding: 10px;
    font-family: arial;
    font-size: 1.2em;
    font-style: bold;
    color: #ffffff !important;
    background-color: #ff762cd5;
    border-radius: 10px;
    -webkit-border-radius: 10px;
    -moz-border-radius: 10px;
    display: block;
}

.fbtn {
    background-color: #ed519ad7;
}

.obtn {
    background-color: #d43a3ad5;
}

.btn:hover {
    transform: scale(1.1);
    transition: all 0.2s ease-in-out;
}

.text-white {
    color: #fff;
    text-decoration: none;
    font-weight: 500;
}

.title-routing {
    color: #363434;
}

h2 {
    margin-top: -18px;
    margin-left: auto;
    margin-right: auto;
    background-color: #ffffff;
    max-width: 95%;
    color: #363434;
    border: solid #afafafc9 2px;
    border-radius: 10px;
    padding: 0px;
    font-family: arial;
}