.icons {
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
}

.icon-container {
    width: fit-content;
    cursor: pointer;
    font-size: 1.5em;
    margin: auto 3rem;
    transition: all 0.3s ease-in-out;
}

.icon-container>p {
    margin-top: 16px;
}

svg {
    max-width: 100%;
    height: fit-content;
}

.icon {
    width: 160px;
    min-width: 150px;
    max-width: 160px;
    height: 160px;
    min-height: 150px;
    max-height: 160px;
}

.icon-container:hover {
    color: #ec6331;
    font-weight: 600;
    /* transition: all 5s ease-in-out; */
}

.icon-container .icon {
    transform: scale(1);
    transition: transform 0.3s ease-in-out;
}

.icon-container:hover .icon {
    transform: scale(1.1);
    position: relative;
}

.icon-container:hover .icon::before {
    content: ' ';
    display: block;

    width: 100%;
    height: 100%;

    padding: 5px;

    border: 1.5px solid #fbd7c9;
    border-right: none;
    border-top: none;
    border-radius: 50%;

    position: absolute;
    top: -5.8px;
    left: -6px;

    animation: border_rounded_load 1.5s linear infinite;
}


.title-gender {
    margin: -20px auto 20px auto;
    background-color: #ffffff;
    max-width: 90%;
    color: #363434;
    font-size: 1.5rem;
    font-weight: 550;
    border-radius: 10px;
    padding: 5px;
    font-family: arial;
}