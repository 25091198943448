body {
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  margin: 0;
}

html {
  width: 100%;
  height: 100%;
}

img {
  max-width: 100%;
}

@keyframes gradient {
  0% {
    background-position: 0% 50%;
  }

  50% {
    background-position: 80% 50%;
  }

  100% {
    background-position: 0% 50%;
  }
}

#root {
  display: block !important;
}

.page-neutre {
  background: linear-gradient(-45deg, #ed5199, #ed5199, #ea570d, #ea570d);
  background-size: 450% 450%;
  animation: gradient 5s ease infinite;
  margin: 0;
  max-width: 100%;
}

.page-femme {
  background: linear-gradient(-45deg, #ed5199, #ddd, #b46e9f, #dd7ba1);
  background-size: 400% 400%;
  animation: gradient 15s ease infinite;
  margin: 0;
  max-width: 100%;
}

.page-homme {
  background: linear-gradient(-45deg, #ee7752, #ddd, #ea570d, #e73c7e);
  background-size: 400% 400%;
  animation: gradient 15s ease infinite;
  margin: 0;
  max-width: 100%;
}

/*alert stripe test*/
.alert {
  font-size: 1.5em;
  width: 100%;
  background-color: #ff0000;
  text-align: center;
  font-style: unset;
  color: rgb(255, 255, 255);
  position: fixed;
  bottom: 0px;
  padding: 10px;
}

/* Margin pour inputs */
.MuiFormControl-root {
  margin-bottom: 10px;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

@media (max-width: 800px) {
  body {
    width: 100vw !important;
  }
}


/*---------------------- SELECT PAIEMENT --------------------------*/
.paymentFormCtn {
  width: 100%;
  margin-bottom: 10px;
}

.paymentForm {
  display: flex;
  width: 100% !important;
  align-self: center;
}

.paymentFormLabel {
  /* font-weight: bold !important;
  font-size: 1.2rem !important; */
}

.paymentForm__btn {
  display: block;
  width: 30%;
  margin: 1rem auto;
  border: none;
  padding: .5rem;
}

/*---------------------- RECAP COMMAND --------------------------*/
.recap-command {
  display: block;
  margin: 15px 10px 30px 10px;
}

.title-command,
.product-command,
.total-command {
  display: flex;
  text-align: start;
  justify-content: space-between;
  gap: 10px;
  padding: 0px;
}

.title-command {
  border-bottom: 1px solid black;
  padding-bottom: 5px;
  font-size: 1.2em;
  font-weight: 700;
}

.total-command {
  border-top: 1px solid black;
  margin-top: 1rem;
  padding-bottom: 5px;
  font-size: 1.2em;
  font-weight: 700;
}

.product-command {
  margin-top: 10px;
}

.total-before-reduc {
  display: flex;
  text-align: start;
  justify-content: end;
  border-top: 1px solid black;
  margin-top: 1rem;
  padding-bottom: 5px;
  font-size: 1.2em;
  font-weight: 700;
  text-decoration: line-through;
  text-decoration-color: #ea570d;
  text-decoration-style: wavy;
}

.total-reduc {
  display: flex;
  text-align: start;
  justify-content: end;
}

.reduc-percent {
  display: flex;
  color: white;
  font-weight: 500;
  background-color: #ea570d;
  border-radius: 10px;
  width: fit-content;
  margin: 5px;
}

.product-price,
.bonus-price,
.reduc-percent {
  align-self: end;
  padding: 10px;
}

/*---------------------- secure icone checkout auto --------------------------*/
.container-secure-icons {
  margin: 15px;
}

/*---------------------- alma checkout auto --------------------------*/

.divEligibility {
  text-align: start;
}